/**
 * Returns the preferred color scheme based on the user's system settings.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/CSS/@media/prefers-color-scheme
 * @returns {ColorScheme} The preferred color scheme
 */
const getPreferredColorScheme = (): ColorScheme => {
  // Default to light if the window object is not available.
  if (typeof window === "undefined" || !window.matchMedia) {
    return "light"
  }

  return window.matchMedia("(prefers-color-scheme: dark)").matches
    ? "dark"
    : "light"
}

export default getPreferredColorScheme
